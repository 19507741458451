<template>
  <CabinetManagements />
</template>

<script>
  import CabinetManagements from '../pages/cabinetManagement/CabinetManagement.vue';

  export default {
    components: {
      CabinetManagements
    },
    name: 'CabinetManagement'
  };
</script>